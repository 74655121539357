@font-face {
  font-family: Mondwest-Regular;
  src: url(Mondwest-Regular.otf);
}

div {
  box-shadow: 0 0 95px rgb(25, 150, 200);
}

p {
  line-height: 2em;
  letter-spacing: 0.1em;
  font-size: 17px;
  margin: 20px;
}

a {
  text-decoration: none;
}

a {
  color: rgb(196, 196, 196);

  text-decoration: overline;
  transition: 0.3s;
}

a:hover {
  transition: 0.3s;
  text-shadow: 0px 7px 3px rgb(152 0 109 / 75%);
}

#theCanvas {
  position: absolute;
  top: 20%;
  box-shadow: 0px 0px 50px rgba(152 0 109 / 75%);
  width: 400px;
  height: 400px;
  right: 2.5%;
}

#bio {
  position: absolute;
  background: #0500c2;
  width: 400px;
  height: 500px;
  top: 20%;
  left: 2.5%;
  box-shadow: 0px 0px 50px rgba(25 150 200 / 75%);
  text-align: center;
}

#bio:hover {
  transition: 0.3s;
  opacity: 100%;
}

#theCanvas:hover {
  transition: 0.3s;
  opacity: 100%;
}

body {
  overflow: hidden;
  height: 100vh;
  position: sticky;
  background-color: rgb(196, 196, 196);
  display: flex;
  flex-direction: column;
  color: rgb(196, 196, 196);
  box-shadow: inset rgba(0, 0, 255, 1) 0px 0px 95px;
  font-family: Mondwest-Regular;
  -youbkit-touch-callout: none;
  -youbkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 850px) {
  p {
    font-size: 12px;
    margin: 20px;
  }

  #bio {
    top: 25%;
    width: 300px;
    height: 375px;
    opacity: 100%;
    left: 2%;
  }

  #theCanvas {
    top: 25%;
    width: 300px;
    height: 300px;
    right: 2%;
  }
}

@media screen and (max-width: 750px) {
  p {
    font-size: 10.5px;
    left: 0px;
    top: -20%;
    margin: 30px;
  }
  #bio {
    width: 300px;
    height: 325px;
    top: 51%;
    left: 30%;
  }

  #theCanvas {
    top: 5%;
    width: 300px;
    height: 300px;
    left: 30%;
  }
}

@media screen and (max-width: 600px) {
  p {
    font-size: 10px;
    left: 0px;
    top: -12%;
    margin: 35px;
  }
  #bio {
    width: 300px;
    height: 325px;
    top: 51%;
    left: 17%;
  }

  #theCanvas {
    top: 5%;
    width: 300px;
    height: 300px;
    left: 17%;
  }
}

@media screen and (max-width: 450px) {
  p {
    font-size: 10px;
    left: 0px;
    top: -5%;
    margin: 40px;
  }
  #bio {
    width: 300px;
    height: 325px;
    top: 50%;
    left: 15%;
  }

  #theCanvas {
    top: 5%;
    width: 300px;
    height: 300px;
    left: 15%;
  }
}

@media screen and (max-width: 350px) {
  p {
    font-size: 10px;
    left: 0px;
    top: -5%;
    margin: 35px;
  }
  #bio {
    width: 275px;
    height: 350px;
    top: 50%;
    left: 10%;
  }
  #defaultCanvas0 {
    width: 275px;
    height: 275px;
  }
  #theCanvas {
    top: 5%;
    width: 275px;
    height: 275px;
    left: 10%;
  }
}
