@font-face {
  font-family: Mondwest-Regular;
  src: url("Mondwest-Regular.590f060c.otf");
}

div {
  box-shadow: 0 0 95px #1996c8;
}

p {
  letter-spacing: .1em;
  margin: 20px;
  font-size: 17px;
  line-height: 2em;
}

a {
  color: #c4c4c4;
  text-decoration: overline;
  transition: all .3s;
}

a:hover {
  text-shadow: 0 7px 3px #98006dbf;
  transition: all .3s;
}

#theCanvas {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 20%;
  right: 2.5%;
  box-shadow: 0 0 50px #98006dbf;
}

#bio {
  width: 400px;
  height: 500px;
  text-align: center;
  background: #0500c2;
  position: absolute;
  top: 20%;
  left: 2.5%;
  box-shadow: 0 0 50px #1996c8bf;
}

#bio:hover, #theCanvas:hover {
  opacity: 1;
  transition: all .3s;
}

body {
  height: 100vh;
  color: #c4c4c4;
  -youbkit-touch-callout: none;
  -youbkit-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #c4c4c4;
  flex-direction: column;
  font-family: Mondwest-Regular;
  display: flex;
  position: sticky;
  overflow: hidden;
  box-shadow: inset 0 0 95px #00f;
}

@media screen and (max-width: 850px) {
  p {
    margin: 20px;
    font-size: 12px;
  }

  #bio {
    width: 300px;
    height: 375px;
    opacity: 1;
    top: 25%;
    left: 2%;
  }

  #theCanvas {
    width: 300px;
    height: 300px;
    top: 25%;
    right: 2%;
  }
}

@media screen and (max-width: 750px) {
  p {
    margin: 30px;
    font-size: 10.5px;
    top: -20%;
    left: 0;
  }

  #bio {
    width: 300px;
    height: 325px;
    top: 51%;
    left: 30%;
  }

  #theCanvas {
    width: 300px;
    height: 300px;
    top: 5%;
    left: 30%;
  }
}

@media screen and (max-width: 600px) {
  p {
    margin: 35px;
    font-size: 10px;
    top: -12%;
    left: 0;
  }

  #bio {
    width: 300px;
    height: 325px;
    top: 51%;
    left: 17%;
  }

  #theCanvas {
    width: 300px;
    height: 300px;
    top: 5%;
    left: 17%;
  }
}

@media screen and (max-width: 450px) {
  p {
    margin: 40px;
    font-size: 10px;
    top: -5%;
    left: 0;
  }

  #bio {
    width: 300px;
    height: 325px;
    top: 50%;
    left: 15%;
  }

  #theCanvas {
    width: 300px;
    height: 300px;
    top: 5%;
    left: 15%;
  }
}

@media screen and (max-width: 350px) {
  p {
    margin: 35px;
    font-size: 10px;
    top: -5%;
    left: 0;
  }

  #bio {
    width: 275px;
    height: 350px;
    top: 50%;
    left: 10%;
  }

  #defaultCanvas0 {
    width: 275px;
    height: 275px;
  }

  #theCanvas {
    width: 275px;
    height: 275px;
    top: 5%;
    left: 10%;
  }
}

/*# sourceMappingURL=index.d5b0e691.css.map */
